export default {
  id: 'SPARK',
  codeName: 'Spark',
  title: 'Learn HTML & CSS',
  description:
    'Learn the basics of HTML and CSS and be able to confidently make websites from scratch. Prior coding knowledge is not required.',
  duration: '8 weeks',
  seats: 10,
  nextStartDate: 'N/A',
  potentialEarnings: 'R20,000pm',
  benefits: [],
  weeklyLessons: [
    {
      title: 'Introduction to Web Development',
      overview:
        'To start with we need to get a good understanding of what the Internet is and where a web developer fits in. We will discuss the tools used by developers and we will guide you in installing these on your machine. We will also cover HTML and HTML5.'
    },
    {
      title: 'Styling Websites part I',
      overview:
        'CSS is the language you use to make your websites look beautiful and readable to the user. Even though CSS is a very powerful tool, it is one of the highly underrated and misunderstood languages for the web. '
    },
    {
      title: 'Styling Websites part II',
      overview:
        'A good understanding of CSS can set you apart from other developers. Simply put, understanding CSS makes it 10 times easier to develop a website. Here we start working with some of the more complex concepts of CSS such as Flexbox for creating CSS Layouts.'
    },
    {
      title: 'Building Responsive Websites',
      overview:
        'One of the key responsibility of a web developer is to make sure that your website works on different devices. You will get a step-by-step walk through on making your websites work on a portrait or landscape phone, laptop, desktop, etc.'
    },
    {
      title: 'Real World Website #1',
      overview:
        'Understanding the basics is important but now it’s time to learn how the professionals do it. We will start building a real world website using best practice concepts like SASS/SCSS and BEM.  We will also introduce NodeJS and NPM.'
    },
    {
      title: 'Real World Website #1 (part II)',
      overview:
        'We will continue working on the website in an effort to expand our knowledge of the best practices for building real world websites. You will also learn how & where to host your website.  We will cover basics step by step, so you will be able to follow along.'
    },
    {
      title: 'Real World Website #2',
      overview:
        'As a developer you won’t always work by yourself. In this project you will work with your colleagues to build another real world website. You will learn how to store your code in such a way that other developers can contribute to the project.'
    },
    {
      title: 'Real World Website #2 (part II)',
      overview:
        'To finish off, we will complete the second real world project. To top that we will learn how to use Continuous Integration/Continuous Deployment (CI/CD) to fast track & automate the process of hosting your website.'
    }
  ],
  fee: 'R7,999',
  paymentPlans: [
    {
      id: 'CASH_PAYMENT',
      title: 'Cash Payment',
      points: [
        {
          point: 'Get 10% off'
        },
        {
          point: 'Pay only R7,199'
        }
      ]
    },
    {
      id: 'SPLIT_PAYMENTS',
      title: 'Split Payments',
      points: [
        {
          point: 'Deposit R1,999'
        },
        {
          point: 'R3,000pm x 2 months'
        }
      ]
    },
    {
      id: 'MONTHLY_PAYMENTS',
      title: 'Monthly Payments',
      points: [
        {
          point: 'Deposit R1,999'
        },
        {
          point: 'R1,100pm x 6 months'
        }
      ]
    }
  ]
};
